import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Button from "../../components/button";
import logo from '../../assets/img/logo_purple.svg';
import FooterLayout from "../../layouts/footer";

const NotFound = () => {

    const [heightFooter, setHeightFooter] = useState();

    return(
        <div 
            style={{ 
                backgroundColor: '#F2EDF5', 
                minHeight: `calc(100vh)`, 
                display: 'flex', 
                justifyContent: 'center' 
            }}
        >
            <Box 
                style={{ 
                    width: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <Typography />
                <Box>
                    <Box
                        sx={{
                            marginTop: '150px',
                            '@media (max-width: 900px)': {
                                marginTop: '160px',
                            }
                        }}
                    >
                        <img src={logo} className="img" style={{ display: 'block', margin: 'auto' }} />
                    </Box>
                    <Box
                        sx={{
                            '@media (min-width: 901px)': {
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                marginTop: '10px',
                            },
                            '@media (max-width: 900px)': {
                                display: 'block',
                                textAlign: 'center',
                                marginTop: '40px',
                            }
                        }}
                    >
                        <Typography 
                            sx={{ 
                                fontSize: '128px', 
                                color: 'rgba(2, 8, 20, 0.1)',
                                lineHeight: '140.8px',
                                '@media (max-width: 900px)': {
                                    fontSize: '48px', 
                                    lineHeight: '52.8px'
                                }
                            }}
                        >
                            erro
                        </Typography>
                        <Typography 
                            sx={{ 
                                fontSize: '220px', 
                                color: 'rgba(144, 31, 234, 1)',
                                lineHeight: '242px',
                                '@media (max-width: 900px)': {
                                    fontSize: '128px', 
                                    lineHeight: '140.8px'
                                }
                            }}
                        >
                            404
                        </Typography>
                    </Box>
                </Box>
                <Typography 
                    sx={{ 
                        fontSize: '32px', 
                        textAlign: 'center',
                        '@media (max-width: 900px)': {
                            fontSize: '16px'
                        }
                    }}
                >
                    ops! Parece que esta página não existe. <br /> Clique no botão e volte a navegar!
                </Typography>
                <Box style={{ margin: 'auto', display: 'block', width: '300px', marginTop: '30px' }}>
                    <Button label="Voltar para home" fullWidth />
                </Box>
                <Box 
                    sx={{
                        marginTop: '120px'
                    }}
                >
                    <FooterLayout handleHeight={(e) => {setHeightFooter(e)}} drawerWidth={0} backgroundColor="transparent" id="footer" />
                </Box>
            </Box>
        </div>
    )
}

export default NotFound;