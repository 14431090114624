import { Container, Box } from "@mui/material";
import Spacer from "../components/spacer";
import logo from '../assets/img/logo_login.svg';
import './auth.css';

const AuthLayout = props => {

    return(
        <>
            <div className="root">
                <Container>
                    <Box className="box">
                        <img src={logo} className="img" />
                        <Spacer direction="vertical" size={30} />
                        {props.children}
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default AuthLayout;