import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import api from './utils/api';

/** Pages */
import VisaoGeral from './pages/visao-geral/visao-geral';
import MeuPainel from './pages/meu-painel/meuPainel';
import Login from './pages/login/login';
import NotFound from './pages/not-found/notFound';
import ForgotPassword from './pages/forgot-password/forgotPassword';
import NewPassword from './pages/new-password/newPassword';
import CentralOperacoes from './pages/central-operacoes/centralOperacoes';
import Tutoriais from './pages/tutoriais/tutoriais';
import MinhaConta from './pages/minha-conta/minha-conta';

import { LinearProgress } from '@mui/material';

const PrivateRoute = ({ component: Component, levelRequired, ...rest }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    api.get('/me', {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('tokenStorage')
      }
    })
    .then(res => {
      setUserInfo(res.data);
      setIsAuthenticated(true);
      setIsAuthCheckComplete(true);
    })
    .catch(error => {
      setIsAuthenticated(false);
      setIsAuthCheckComplete(true);
    });
  }, []);

  if (!isAuthCheckComplete) {
    return <LinearProgress />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (levelRequired && userInfo?.level !== levelRequired) {
    return <Redirect to="/visao-geral" />;
  }

  return (
    <Route
      {...rest}
      render={props => <Component {...props} userInfo={userInfo} />}
    />
  );

};

const RedirectToHome = () => {
  return <Redirect to="/login" />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={RedirectToHome} />
      <Route path="/login" exact={true} component={Login} />
      <Route path="/esqueceu-senha" exact={true} component={ForgotPassword} />
      <Route path="/nova-senha" component={NewPassword} />
      <PrivateRoute path="/visao-geral" exact={true} component={VisaoGeral} />
      <PrivateRoute path="/meu-painel" exact={true} component={MeuPainel} />
      <PrivateRoute path="/minha-conta" exact={true} component={MinhaConta} levelRequired="manufacturer" />
      <PrivateRoute path="/tutoriais" exact={true} component={Tutoriais} levelRequired="manufacturer" />
      <PrivateRoute path="/central-operacoes" exact={true} component={CentralOperacoes} levelRequired="managerial" />
      <Route path="/*" exact={true} component={NotFound} />
    </Switch>
  </BrowserRouter>
);

reportWebVitals();
