import { Typography, Box, Grid, Modal, Hidden, CircularProgress, Skeleton } from "@mui/material";
import DefaultLayout from "../../layouts/default";
import './centralOperacoes.css'
import { useEffect, useState } from "react";
import Spacer from "../../components/spacer";
import Input from "../../components/input";
import Button from "../../components/button";
import User from "./user";
import Company from "./company";
import api from "../../utils/api";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '32px',
    p: 4,
    '@media (max-width: 767px)': {
        width: '70vw'
    }
};

const CentralOperacoes = props => {

    const [tab, setTab] = useState('companies');
    const [openNewUser, setOpenNewUser] = useState(false);
    const [openNewCompanie, setOpenNewCompanie] = useState(false);
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [newCompanyName, setNewCompanyName] = useState();
    const [newCompanyUrl, setNewCompanyUrl] = useState();
    const [newCompanyLink, setNewCompanyLink] = useState();
    const [isLoadingNewCompany, setIsLoadingNewCompany] = useState(false);
    const [optionsCompanies, setOptionsCompanies] = useState([]);
    const [isLoadingNewUser, setIsLoadingNewUser] = useState(false);
    const [newUserName, setNewUserName] = useState();
    const [newUserEmail, setNewUserEmail] = useState();
    const [newUserCompany, setNewUserCompany] = useState();
    const [newUserStatus, setNewUserStatus] = useState();
    const [isLoadignUsers, setIsLoadignUsers] = useState(false);
    const [isLoadignCompanies, setIsLoadignCompanies] = useState(false);

    useEffect(() => {

        handleGetCompanies();
        handleGetUsers();

    }, [])

    const [companyFilterSearch, setCompanyFilterSearch] = useState('');

    useEffect(() => {

        let options = [];

        options.push({
            label: 'Selecione',
            value: null
        })

        if(companies.length > 0){
            companies?.forEach(function(company) {
                options.push({
                    label: company.name,
                    value: company.id
                })
            });
        }

        setOptionsCompanies(options);

    }, [companies])

    const handleGetCompanies = () => {

        setIsLoadignCompanies(true);

        api.get(`/company?search=${companyFilterSearch}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('tokenStorage')
            }
        })
        .then(res => {
            setCompanies(res.data);
            setIsLoadignCompanies(false);
        })
        .catch(error => {
            setIsLoadignCompanies(false);
            alert("Erro ao buscar empresas");
        });

    }

    const handleNewCompany = () => {

        setIsLoadingNewCompany(true);

        api.post('/company/new', {
            name: newCompanyName,
            url: newCompanyUrl,
            link: newCompanyLink
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingNewCompany(false);
            setOpenNewCompanie(!openNewCompanie);
            setNewCompanyLink('');
            setNewCompanyName('');
            setNewCompanyUrl('');
            handleGetCompanies();
        })
        .catch(error => {
            setIsLoadingNewCompany(false);
            alert("Erro ao cadastrar empresa");
        });

    }

    const handleGetUsers = (status = '') => {

        setIsLoadignUsers(true);

        let filterStatus = userFilterStatus;

        if(status){
            filterStatus = status;
        }

        api.get(`/users?status=${filterStatus}&search=${userFilterSearch}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('tokenStorage')
            }
        })
        .then(res => {
            setIsLoadignUsers(false);
            setUsers(res.data);
        })
        .catch(error => {
            setIsLoadignUsers(false);
            alert("Erro ao buscar usuários");
        });

    }

    const [statusregisterUser, setStatusregisterUser] = useState(1);

    const handleNewUser = () => {

        setIsLoadingNewUser(true);

        api.post('/register', {
            name: newUserName,
            email: newUserEmail,
            company: newUserCompany,
            status: newUserStatus
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingNewUser(false);
            // setOpenNewUser(!openNewUser);
            setStatusregisterUser(2);
            setNewUserName('');
            setNewUserEmail('');
            setNewUserCompany('');
            setNewUserStatus('');
            // handleGetUsers();
        })
        .catch(error => {
            setIsLoadingNewUser(false);
            alert("Erro ao cadastrar usuário");
        });

    }

    const [userFilterStatus, setUserFilterStatus] = useState('');
    const [userFilterSearch, setUserFilterSearch] = useState('');

    useEffect(() => {
        handleGetUsers(userFilterStatus)
    }, [userFilterStatus])

    return(
        <>
            <DefaultLayout userInfo={props.userInfo}>
                <Typography variant="h6" style={{ color: '#EA1A7F' }}>Administrador</Typography>
                <Typography variant="h4" style={{ fontWeight: '500' }}>Central de operações</Typography>
                <Box 
                    sx={{ 
                        mt: 3, 
                        '@media (min-width: 768px)': {
                            width: '100%' 
                        }
                    }}
                    className="boxTab"
                >
                    <Box onClick={() => {setTab('companies')}} className={tab === 'companies' ? 'activeTab' : 'inactiveTab'}>
                        <Typography>Empresas</Typography>
                    </Box>
                    <Box onClick={() => {setTab('users');setCompanyFilterSearch('');handleGetCompanies();}} className={tab === 'users' ? 'activeTab' : 'inactiveTab'}>
                        <Typography>Usuários</Typography>
                    </Box>
                </Box>
                <Spacer direction="vertical" size={20} />
                {tab === 'companies' && (
                    <>
                        <Box className="boxContent">
                            <Spacer direction="vertical" size={20} />
                            <Grid spacing={2} container alignItems="center">
                                <Grid item md={10} xs={12}>
                                    <Input 
                                        fullWidth 
                                        size="small" 
                                        placeholder="Pesquise aqui" 
                                        value={companyFilterSearch} 
                                        onChange={(e) => {setCompanyFilterSearch(e.target.value)}} 
                                        endAdornment={true}
                                        endAdornmentIcon={<SearchRoundedIcon />}
                                        handleClickEndAdornment={() => {handleGetCompanies()}}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Button fullWidth label="Adicionar" onClick={() => {setOpenNewCompanie(!openNewCompanie)}} />
                                </Grid>
                            </Grid>
                            <Hidden smDown>
                                <Spacer direction="vertical" size={20} />
                                <Grid spacing={3} container>
                                    <Grid item md={3}>
                                        <Typography fontWeight="bold">Empresa</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography fontWeight="bold">URL da empresa</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography fontWeight="bold">Link embed</Typography>
                                    </Grid>
                                    <Grid item md={3}></Grid>
                                </Grid>
                                <Spacer direction="vertical" size={10} />
                                {isLoadignCompanies ?
                                    <Grid spacing={3} container>
                                        <Grid item md={3}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }} />
                                        </Grid>
                                        <Grid item md={3}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }}  />
                                        </Grid>
                                        <Grid item md={3}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }}  />
                                        </Grid>
                                        <Grid item md={3}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }}  />
                                        </Grid>
                                    </Grid>
                                :
                                    <>
                                        {companies.length > 0 && companies?.map((company, index) => (
                                            <>
                                                <Company handleGetCompanies={() => {handleGetCompanies()}} key={index} company={company} />
                                            </>
                                        ))}
                                    </>
                                }
                                <Spacer direction="vertical" size={20} />
                            </Hidden>
                        </Box>
                        <Hidden smUp>
                            {isLoadignCompanies ?
                                <>
                                    <Spacer direction="vertical" size={20} />
                                    <Skeleton variant="rounded" width="100%" height={200} style={{ borderRadius: '32px' }}  />
                                </>
                            :
                                <>
                                    {companies.length > 0 && companies?.map((company, index) => (
                                        <>
                                            <Spacer direction="vertical" size={20} />
                                            <Box className="boxContent">
                                                <Company handleGetCompanies={() => {handleGetCompanies()}} key={index} company={company} />
                                            </Box>
                                        </>
                                    ))}
                                </>
                            }
                            <Spacer direction="vertical" size={20} />
                        </Hidden>
                        <Modal
                            open={openNewCompanie}
                            onClose={() => {setOpenNewCompanie(!openNewCompanie)}}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h5" fontWeight="bold">
                                    Adicionar empresa
                                </Typography>
                                <Spacer direction="vertical" size={20} />
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <Typography>Nome</Typography>
                                        <Input placeholder="Digite o nome" fullWidth value={newCompanyName} onChange={(e) => {setNewCompanyName(e.target.value)}} />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography>URL</Typography>
                                        <Input placeholder="Digite a url" fullWidth value={newCompanyUrl} onChange={(e) => {setNewCompanyUrl(e.target.value)}} />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography>Link embed</Typography>
                                        <Input placeholder="Digite o link" fullWidth value={newCompanyLink} onChange={(e) => {setNewCompanyLink(e.target.value)}} />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Box 
                                            sx={{ 
                                                display: 'flex',
                                                gap: '10px',
                                                '@media (max-width: 900px)': {
                                                    display: 'grid'
                                                }
                                            }}
                                        >
                                            <Button color="gray" onClick={() => {setOpenNewCompanie(!openNewCompanie)}} size="large" variant="contained" fullWidth label="Cancelar" />
                                            <Button disabled={!newCompanyName || !newCompanyUrl || !newCompanyLink} onClick={() => {handleNewCompany()}} size="large" variant="contained" fullWidth label={isLoadingNewCompany ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Salvar'} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                    </>
                )}
                {tab === 'users' && (
                    <>
                        <Box className="boxContent">
                            <Spacer direction="vertical" size={20} />
                            <Grid spacing={2} container alignItems="center">
                                <Grid item md={7} xs={12}>
                                    <Input 
                                        fullWidth 
                                        size="small" 
                                        placeholder="Pesquise aqui" 
                                        value={userFilterSearch} 
                                        onChange={(e) => {
                                            setUserFilterSearch(e.target.value);
                                        }}
                                        endAdornment={true}
                                        endAdornmentIcon={<SearchRoundedIcon />}
                                        handleClickEndAdornment={() => {handleGetUsers()}}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Input 
                                        fullWidth
                                        size="small"
                                        type="select"
                                        options={[
                                            {
                                                label: 'Ordenar por',
                                                value: ''
                                            },
                                            {
                                                label: 'Ativo',
                                                value: 'ativo'
                                            },
                                            {
                                                label: 'Inativo',
                                                value: 'inativo'
                                            },
                                            {
                                                label: 'Pendente',
                                                value: 'pendente'
                                            }
                                        ]}
                                        value={userFilterStatus}
                                        onChange={(e)=> {
                                            setUserFilterStatus(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Button fullWidth label="Adicionar" onClick={() => {setOpenNewUser(!openNewUser)}} />
                                </Grid>
                            </Grid>
                            <Hidden smDown>
                                <Spacer direction="vertical" size={20} />
                                <Grid spacing={3} container>
                                    <Grid item md={3}>
                                        <Typography fontWeight="bold">Usuário</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography fontWeight="bold">Empresa</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography fontWeight="bold">Email</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography fontWeight="bold">Status</Typography>
                                    </Grid>
                                    <Grid item md={2}></Grid>
                                </Grid>
                                <Spacer direction="vertical" size={10} />
                                {isLoadignUsers ?
                                    <Grid spacing={3} container>
                                        <Grid item md={3}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }} />
                                        </Grid>
                                        <Grid item md={2}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }}  />
                                        </Grid>
                                        <Grid item md={3}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }}  />
                                        </Grid>
                                        <Grid item md={2}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }}  />
                                        </Grid>
                                        <Grid item md={2}>
                                            <Skeleton variant="rounded" width="100%" height={30} style={{ borderRadius: '50px' }}  />
                                        </Grid>
                                    </Grid>
                                :
                                    <>
                                        {users?.map((user, index) => (
                                            <>
                                                <User key={index} user={user} handleGetUsers={() => {handleGetUsers()}} optionsCompanies={optionsCompanies} />
                                            </>
                                        ))}
                                    </>
                                }
                                <Spacer direction="vertical" size={20} />
                            </Hidden>
                        </Box>
                        <Hidden smUp>
                        {isLoadignUsers ?
                            <>
                                <Spacer direction="vertical" size={20} />
                                <Skeleton variant="rounded" width="100%" height={200} style={{ borderRadius: '32px' }}  />
                            </>
                        :
                            <>
                                {users?.map((user, index) => (
                                    <>
                                        <Spacer direction="vertical" size={20} />
                                        <Box className="boxContent">
                                            <User key={index} user={user} handleGetUsers={() => {handleGetUsers()}} optionsCompanies={optionsCompanies} />
                                        </Box>
                                    </>
                                ))}
                                <Spacer direction="vertical" size={20} />
                            </>
                        }
                        </Hidden>
                        <Modal
                            open={openNewUser}
                            onClose={() => {setOpenNewUser(!openNewUser)}}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                {statusregisterUser == 1 && (
                                    <>
                                        <Typography id="modal-modal-title" variant="h5" fontWeight="bold">
                                            Adicionar usuário
                                        </Typography>
                                        <Spacer direction="vertical" size={20} />
                                        <Grid container spacing={3}>
                                            <Grid item md={12} xs={12}>
                                                <Typography>Nome</Typography>
                                                <Input placeholder="Digite o nome" fullWidth value={newUserName} onChange={(e) => {setNewUserName(e.target.value)}} />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Typography>E-mail</Typography>
                                                <Input placeholder="Digite o e-mail" fullWidth value={newUserEmail} onChange={(e) => {setNewUserEmail(e.target.value)}} />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Typography>Empresa</Typography>
                                                <Input 
                                                    type="select" 
                                                    fullWidth 
                                                    options={optionsCompanies}
                                                    value={newUserCompany} 
                                                    onChange={(e) => {setNewUserCompany(e.target.value)}}
                                                />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Typography>Status</Typography>
                                                <Input 
                                                    type="select" 
                                                    fullWidth 
                                                    options={[
                                                        {
                                                            label: 'Selecione',
                                                            value: null
                                                        },
                                                        {
                                                            label: 'Ativo',
                                                            value: 'ativo'
                                                        },
                                                        {
                                                            label: 'Inativo',
                                                            value: 'inativo'
                                                        },
                                                        {
                                                            label: 'Pendente',
                                                            value: 'pendente'
                                                        }
                                                    ]}
                                                    value={newUserStatus}
                                                    onChange={(e) => {setNewUserStatus(e.target.value)}}
                                                />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Box 
                                                    sx={{ 
                                                        display: 'flex',
                                                        gap: '10px',
                                                        '@media (max-width: 900px)': {
                                                            display: 'grid'
                                                        }
                                                    }}
                                                >
                                                    <Button color="gray" onClick={() => {setOpenNewUser(!openNewUser)}} size="large" variant="contained" fullWidth label="Cancelar" />
                                                    <Button disabled={!newUserName || !newUserEmail || !newUserCompany || !newUserStatus} onClick={() => {handleNewUser()}} size="large" variant="contained" fullWidth label={isLoadingNewUser ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Salvar'}/>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {statusregisterUser == 2 && (
                                    <>
                                        <Typography variant="h5" sx={{ textAlign: 'center' }}>Cadastro realizado com sucesso!</Typography>
                                        <Spacer direction="vertical" size={10} />
                                        <Button 
                                            onClick={() => {
                                                handleGetUsers();
                                                setStatusregisterUser(1);
                                                setOpenNewUser(!openNewUser);
                                            }} 
                                            size="large" 
                                            variant="contained" 
                                            label="Entendi" 
                                            sx={{ 
                                                margin: 'auto', 
                                                display: 'block', 
                                                width: '140px' 
                                            }} 
                                        />
                                    </>
                                )}
                            </Box>
                        </Modal>
                        <Spacer direction="vertical" size={20} />
                    </>
                )}
            </DefaultLayout>
        </>
    )
}

export default CentralOperacoes;