import { Typography } from "@mui/material";
import DefaultLayout from "../../layouts/default";
import styles from './tutoriais.css';

const Tutoriais = props => {

    return(
        <>
            <DefaultLayout userInfo={props.userInfo}>
                <Typography variant="h6" style={{ color: '#EA1A7F' }}>Aprenda</Typography>
                <Typography variant="h4" style={{ fontWeight: '500' }}>Tutorial plataforma</Typography>
                <iframe 
                    className={styles.iframe}
                    src="https://www.youtube.com/embed/v_25EOdMl1k?si=tEHMYB4nkZ8sE4dJ" 
                    title="Vitrine Revit" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                />
            </DefaultLayout>
        </>
    )
}

export default Tutoriais;