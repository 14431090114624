import { Typography } from "@mui/material";
import DefaultLayout from "../../layouts/default";
import { useEffect, useState } from "react";
import api from "../../utils/api";

const MeuPainel = props => {

    const [link, setLink] = useState();

    useEffect(() => {

        api.get('/link', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('tokenStorage')
            }
        })
        .then(res => {
            setLink(res.data);
        })
        .catch(error => {
            alert("Erro ao buscar link");
        });

    }, [])

    return(
        <>
            <DefaultLayout userInfo={props.userInfo}>
                <Typography variant="h6" style={{ color: '#EA1A7F' }}>{props?.userInfo?.name}</Typography>
                <Typography variant="h4" style={{ fontWeight: '500' }}>Meu painel</Typography>
                <iframe 
                    width="100%" 
                    src={link}
                    frameborder="0" 
                    style={{ 
                        border: 0, 
                        minHeight: '200vh', 
                        marginTop: '20px',
                        marginBottom: '20px'
                    }} 
                    allowfullscreen 
                />
            </DefaultLayout>
        </>
    )
}

export default MeuPainel;