import { Grid, Typography, CircularProgress } from "@mui/material"
import Button from "../../components/button"
import Input from "../../components/input"
import Spacer from "../../components/spacer"
import AuthLayout from "../../layouts/auth"
import './forgotPassword.css'
import { Link } from 'react-router-dom';
import { useState } from "react";
import API from "../../utils/api"

const ForgotPassword = () => {

    const [email, setEmail] = useState();
    const [isLoading, setIsLoading] = useState();

    const handleRecovery = () => {

        setIsLoading(true);

        API.post('/recovery-password' , {
            email: email
        })
        .then(res => {

            setEmail('');
            setIsLoading(false);

            alert("E-mail enviado! Acesse sua caixa de entrada.");

        })
        .catch(err => {

            setIsLoading(false);
            alert("E-mail não localizado");
            
        })

    }

    return(
        <>
            <AuthLayout>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Typography className="title">Esqueceu sua <br /> senha?</Typography>
                        <Spacer direction="vertical" size={10} />
                        <Typography className="subtitle" color="textSecondary">Insira seu e-mail e verifique sua caixa de entrada para mudar a sua senha. </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography>E-mail</Typography>
                        <Input placeholder="Digite seu e-mail" fullWidth value={email} onChange={(e) => {setEmail(e.target.value)}} />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button 
                            disabled={!email}
                            size="large" 
                            variant="contained" 
                            fullWidth 
                            label={isLoading ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Recuperar acesso'} 
                            onClick={() => {handleRecovery()}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12} className="footerForgot">
                        <Link to="login" className="link">
                            <Typography className="forgot">Já tem uma conta? <span>Entrar</span></Typography>
                        </Link>
                    </Grid>
                </Grid>
            </AuthLayout>
        </>
    )
}

export default ForgotPassword;