import { Typography, Box, Avatar, CircularProgress } from "@mui/material";
import DefaultLayout from "../../layouts/default";
import UserIcon from '../../assets/icons/user.svg';
import AccountIcon from '../../assets/icons/account.svg';
import { useEffect, useState, useRef } from "react";
import Input from "../../components/input";
import Spacer from "../../components/spacer";
import Button from "../../components/button";
import ImportIcon from '../../assets/icons/import.svg';
import ChatIcon from '../../assets/icons/chat.svg';
import { Link } from 'react-router-dom';
import api from "../../utils/api";

const MinhaConta = props => {

    useEffect(() => {
        console.log("Infos: ", props.userInfo);
    }, [props.userInfo])

    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [isLoadingUpdatePassword, setIsLoadingUpdatePassword] = useState(false);
    const [company, setCompany] = useState(props?.userInfo?.company_name);
    const [property, setProperty] = useState(props?.userInfo?.name);
    const [cnpj, setCnpj] = useState(props?.userInfo?.cnpj);
    const [cellphone, setCellphone] = useState(props?.userInfo?.cellphone);
    const [address, setAddress] = useState(props?.userInfo?.address);
    const [cityState, setCityState] = useState(props?.userInfo?.city_state);
    const [cep, setCep] = useState(props?.userInfo?.cep);
    const [email, setEmail] = useState(props?.userInfo?.email);
    const [password, setPassword] = useState();
    const [logotipo, setLogotipo] = useState(props?.userInfo?.logotipo);

    const inputRef = useRef(null);

    const handleUploadClick = () => {
        inputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const imageUrl = URL.createObjectURL(selectedFile);
            setLogotipo(imageUrl);
        }
    };

    const handleUpdateData = () => {

        setIsLoadingUpdate(true);

        api.post(`/users/edit/${props?.userInfo?.id}`, {
            company: company,
            name: property,
            cnpj: cnpj,
            cellphone: cellphone,
            address: address,
            cityState: cityState,
            cep: cep
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingUpdate(false);
            alert("Dados alterados com sucesso");
        })
        .catch(error => {
            setIsLoadingUpdate(false);
            alert("Erro ao alterar dados");
        });

    }

    const handleUpdatePassword = () => {

        setIsLoadingUpdatePassword(true);

        api.post(`/users/password/edit/${props?.userInfo?.id}`, {
            password
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingUpdatePassword(false);
            alert("Senha alterada com sucesso");
        })
        .catch(error => {
            setIsLoadingUpdatePassword(false);
            alert("Erro ao alterar senha");
        });

    }

    return(
        <>
            <DefaultLayout userInfo={props.userInfo}>
                <Typography variant="h6" style={{ color: '#EA1A7F' }}>{props?.userInfo?.name}</Typography>
                <Typography variant="h4" style={{ fontWeight: '500' }}>Minha conta</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '20px',
                        marginTop: '20px',
                        '@media (max-width: 767px)': {
                            display: 'grid'
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: '50%',
                            '@media (max-width: 767px)': {
                                width: '100%',
                                display: 'grid'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#F2EDF5',
                                borderRadius: '32px',
                                padding: '20px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ width: '100%'}}>
                                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <img src={UserIcon} />
                                    <Typography style={{ fontWeight: 'bold' }}>Dados da empresa</Typography>
                                </Box>
                                <Spacer direction="vertical" size={20} />
                                <Typography>Empresa</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite o nome" fullWidth value={company} onChange={(e) => {setCompany(e.target.value)}} />
                                <Spacer direction="vertical" size={10} />
                                <Typography>Proprietário</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite o nome" fullWidth value={property} onChange={(e) => {setProperty(e.target.value)}} />
                                <Spacer direction="vertical" size={10} />
                                <Typography>CNPJ</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite o CNPJ" fullWidth value={cnpj} onChange={(e) => {setCnpj(e.target.value)}} />
                                <Spacer direction="vertical" size={10} />
                                <Typography>Telefone</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite o telefone" fullWidth value={cellphone} onChange={(e) => {setCellphone(e.target.value)}} />
                                <Spacer direction="vertical" size={10} />
                                <Typography>Endereço</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite o endereço" fullWidth value={address} onChange={(e) => {setAddress(e.target.value)}} />
                                <Spacer direction="vertical" size={10} />
                                <Typography>Cidade/Estado</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite a cidade e estado" fullWidth value={cityState} onChange={(e) => {setCityState(e.target.value)}} />
                                <Spacer direction="vertical" size={10} />
                                <Typography>CEP</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite o cep" fullWidth value={cep} onChange={(e) => {setCep(e.target.value)}} />
                                <Spacer direction="vertical" size={20} />
                                <hr style={{ border: '0.5px solid rgba(2, 8, 20, 0.1)' }} />
                                <Spacer direction="vertical" size={20} />
                                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Button onClick={() => {handleUpdateData()}} label={isLoadingUpdate ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Salvar alterações'} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '50%',
                            display: 'grid',
                            gap: '20px',
                            '@media (max-width: 767px)': {
                                width: '100%'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#F2EDF5',
                                borderRadius: '32px',
                                padding: '20px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ width: '100%'}}>
                                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <img src={AccountIcon} />
                                    <Typography style={{ fontWeight: 'bold' }}>Dados da conta</Typography>
                                </Box>
                                <Spacer direction="vertical" size={20} />
                                <Typography>E-mail</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite o email" fullWidth value={email} onChange={(e) => {setEmail(e.target.value)}} />
                                <Spacer direction="vertical" size={10} />
                                <Typography>Nova senha</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Input size="small" placeholder="Digite a nova senha" fullWidth value={password} onChange={(e) => {setPassword(e.target.value)}} />
                                {/* <Spacer direction="vertical" size={10} />
                                <Typography>Logo da empresa</Typography>
                                <Spacer direction="vertical" size={5} />
                                <Avatar style={{ width: '100px', height: '100px', margin: 'auto' }} src={logotipo} />
                                <Spacer direction="vertical" size={10} />
                                <input
                                    type="file"
                                    ref={inputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <Button onClick={handleUploadClick} sx={{ border: '1px solid #901FEA', color: '#901FEA', backgroundColor: '#F2EDF5', margin: 'auto', display: 'flex', alignItems: 'center' }}>Fazer upload &nbsp; <img src={ImportIcon} /></Button> */}
                                <Spacer direction="vertical" size={20} />
                                <hr style={{ border: '0.5px solid rgba(2, 8, 20, 0.1)' }} />
                                <Spacer direction="vertical" size={20} />
                                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Button onClick={() => {handleUpdatePassword()}} label={isLoadingUpdatePassword ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Salvar alterações'} />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: '#F2EDF5',
                                borderRadius: '32px',
                                padding: '20px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ width: '100%'}}>
                                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <img src={ChatIcon} />
                                    <Typography style={{ fontWeight: 'bold' }}>Suporte</Typography>
                                </Box>
                                <Spacer direction="vertical" size={20} />
                                <Typography variant="body2">Está com dúvidas sobre a plataforma? Separamos uma área para você aprender!</Typography>
                                <Spacer direction="vertical" size={20} />
                                <Link to="/tutoriais">
                                    <Button label="Aprender agora" />
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DefaultLayout>
        </>
    )
}

export default MinhaConta;