import { Box, Typography, CircularProgress } from "@mui/material";
import DefaultLayout from "../../layouts/default";
import { useState, useEffect } from "react";
import Spacer from "../../components/spacer";
import Input from "../../components/input";
import Button from "../../components/button";
import api from "../../utils/api";

const VisaoGeral = props => {

    const [url, setUrl] = useState('');
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

    useEffect(() => {
        api.get('/configs', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('tokenStorage')
            }
        })
        .then(res => {
            setUrl(res.data.general_vision_url);
        })
        .catch(error => {});
    }, []);

    const handleUpdateUrl = () => {

        setIsLoadingUpdate(true);

        api.post('/configs/update', {
            general_vision_url: url
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingUpdate(false);
        })
        .catch(error => {
            setIsLoadingUpdate(false);
        });

    }

    return(
        <>
            <DefaultLayout userInfo={props.userInfo}>
                <Typography variant="h6" style={{ color: '#EA1A7F' }}>{props?.userInfo?.name}</Typography>
                <Typography variant="h4" style={{ fontWeight: '500' }}>Visão geral</Typography>
                {props?.userInfo?.level == 'managerial' && (
                     <Box
                        sx={{
                            backgroundColor: '#F2EDF5',
                            borderRadius: '32px',
                            padding: '20px',
                            marginTop: '20px'
                        }}
                    >
                        <Typography>URL do Looker para visão geral</Typography>
                        <Spacer direction="vertical" size={10} />
                        <Input size="small" placeholder="Digite o nome" fullWidth value={url} onChange={(e) => {setUrl(e.target.value)}} />
                        <Spacer direction="vertical" size={10} />
                        <Button onClick={() => {handleUpdateUrl()}} label={isLoadingUpdate ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Salvar'} />
                    </Box>
                )}
                <iframe 
                    width="100%" 
                    src={url}
                    frameborder="0" 
                    style={{ 
                        border: 0, 
                        minHeight: '200vh', 
                        marginTop: '20px',
                        marginBottom: '20px'
                    }} 
                    allowfullscreen 
                />
            </DefaultLayout>
        </>
    )
}

export default VisaoGeral;