import { Grid, Box, Typography, Modal, CircularProgress } from "@mui/material";
import Spacer from "../../components/spacer";
import { useState } from "react";
import Input from "../../components/input";
import Button from "../../components/button";
import api from "../../utils/api";
import EditIcon from '../../assets/icons/pencil.svg';
import TrashIcon from '../../assets/icons/trash.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '32px',
    p: 4,
    '@media (max-width: 767px)': {
        width: '70vw'
    }
};

const User = props => {

    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoadingEditUser, setIsLoadingEditUser] = useState(false);
    const [newUserName, setNewUserName] = useState(props.user?.name);
    const [newUserEmail, setNewUserEmail] = useState(props.user?.email);
    const [newUserCompany, setNewUserCompany] = useState(props.user?.company);
    const [newUserStatus, setNewUserStatus] = useState(props.user?.status);

    function getBackgroundColor(status){
        let backgroundColor = '';
        if(status === 'ativo'){
            backgroundColor = '#0BB07B'
        }else if(status === 'inativo'){
            backgroundColor = 'rgba(2, 8, 20, 0.5)';
        }else if(status === 'pendente'){
            backgroundColor = '#FD9E2E';
        }
        return backgroundColor;
    }

    function getStatus(status){
        let label = '';
        if(status === 'ativo'){
            label = 'Ativo'
        }else if(status === 'inativo'){
            label = 'Inativo';
        }else if(status === 'pendente'){
            label = 'Pendente';
        }
        return label;
    }

    const handleEditUser = () => {

        setIsLoadingEditUser(true);

        api.post(`/users/edit/${props.user?.id}`, {
            name: newUserName,
            email: newUserEmail,
            company: newUserCompany,
            status: newUserStatus
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingEditUser(false);
            setOpenEdit(!openEdit);
            props.handleGetUsers();
        })
        .catch(error => {
            setIsLoadingEditUser(false);
            alert("Erro ao editar usuário");
        });

    }

    const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);

    const handleDeleteUser = () => {

        setIsLoadingDeleteUser(true);

        api.post(`/users/delete/${props.user?.id}`, {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingDeleteUser(false);
            setOpenDelete(!openDelete);
            props.handleGetUsers();
        })
        .catch(error => {
            setIsLoadingDeleteUser(false);
            alert("Erro ao deletar usuário");
        });

    }
    
    return(
        <>
            <Spacer direction="vertical" size={10} />
            <Grid spacing={3} container alignItems="center">
                <Grid item md={3} xs={12}>
                    <Typography variant="body2">{props.user.name}</Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                    <Typography variant="body2">{props.user.company}</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Typography variant="body2">{props.user.email}</Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                    <Box 
                        sx={{ 
                            backgroundColor: getBackgroundColor(props.user.status), 
                            padding: '2px 16px 2px 16px',
                            borderRadius: '6px',
                            width: 'fit-content',
                            color: '#FFF'
                        }}
                    >
                        <Typography variant="body2">{getStatus(props.user.status)}</Typography>
                    </Box>
                </Grid>
                <Grid item md={2} xs={12} 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        '@media (max-width: 900px)': {
                            justifyContent: 'flex-start'
                        }
                    }}
                >
                    <img style={{ cursor: 'pointer', marginRight: '20px' }} src={EditIcon} onClick={() => {setOpenEdit(true)}} />
                    <img style={{ cursor: 'pointer' }} src={TrashIcon} onClick={() => {setOpenDelete(true)}} />
                </Grid>
            </Grid>
            <Spacer direction="vertical" size={10} />
            <Modal
                open={openEdit}
                onClose={() => {setOpenEdit(!openEdit)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" fontWeight="bold">
                        Editar usuário
                    </Typography>
                    <Spacer direction="vertical" size={20} />
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Typography>Nome</Typography>
                            <Input placeholder="Digite o nome" fullWidth value={newUserName} onChange={(e) => {setNewUserName(e.target.value)}} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography>E-mail</Typography>
                            <Input placeholder="Digite o e-mail" fullWidth value={newUserEmail} onChange={(e) => {setNewUserEmail(e.target.value)}} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography>Empresa</Typography>
                            <Input 
                                type="select" 
                                fullWidth 
                                options={props.optionsCompanies}
                                value={newUserCompany} 
                                onChange={(e) => {setNewUserCompany(e.target.value)}}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography>Status</Typography>
                            <Input 
                                type="select" 
                                fullWidth 
                                options={[
                                    {
                                        label: 'Selecione',
                                        value: null
                                    },
                                    {
                                        label: 'Ativo',
                                        value: 'ativo'
                                    },
                                    {
                                        label: 'Inativo',
                                        value: 'inativo'
                                    },
                                    {
                                        label: 'Pendente',
                                        value: 'pendente'
                                    }
                                ]}
                                value={newUserStatus}
                                onChange={(e) => {setNewUserStatus(e.target.value)}}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box 
                                sx={{ 
                                    display: 'flex',
                                    gap: '10px',
                                    '@media (max-width: 900px)': {
                                        display: 'grid'
                                    }
                                }}
                            >
                                <Button color="gray" onClick={() => {setOpenEdit(!openEdit)}} size="large" variant="contained" fullWidth label="Cancelar" />
                                <Button disabled={!newUserName || !newUserEmail || !newUserCompany || !newUserStatus || isLoadingEditUser} onClick={() => {handleEditUser()}} size="large" variant="contained" fullWidth label={isLoadingEditUser ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Salvar'}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openDelete}
                onClose={() => {setOpenDelete(!openDelete)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" fontWeight="bold">
                        Deletar usuário
                    </Typography>
                    <Spacer direction="vertical" size={20} />
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Typography>{newUserName}</Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box 
                                sx={{ 
                                    display: 'flex',
                                    gap: '10px',
                                    '@media (max-width: 900px)': {
                                        display: 'grid'
                                    }
                                }}
                            >
                                <Button color="gray" onClick={() => {setOpenDelete(!openDelete)}} size="large" variant="contained" fullWidth label="Cancelar" />
                                <Button disabled={isLoadingDeleteUser} onClick={() => {handleDeleteUser()}} size="large" variant="outlined" fullWidth label={isLoadingDeleteUser? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Deletar'} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default User;