import { Button as ButtonMui } from "@mui/material";

const Button = props => {

    function getColors(color){
        let backgroundColor = '#901FEA';
        let colorText = '#FFF';
        if(color === 'gray'){
            backgroundColor = '#F2EDF5';
            colorText = '#020814';
        }
        return {
            'color': colorText,
            'backgroundColor': backgroundColor
        }
    }

    return(
        <>
            <ButtonMui 
                fullWidth={props?.fullWidth && props?.fullWidth}
                sx={{
                    backgroundColor: '#FFF',
                    textTransform: 'none',
                    borderRadius: '30px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    backgroundColor: getColors(props?.color).backgroundColor,
                    color: getColors(props?.color).color,
                    '&:hover': {
                        backgroundColor: '#F2EDF5',
                        color: '#020814',
                        // outline: '1px solid #901FEA'
                    },
                    width: props?.width && props?.width,
                    ...props.sx
                }}
                size={props?.size && props?.size}
                onClick={(event) => {
                    if(props.onClick){
                        props.onClick(event)
                    }
                }}
                disabled={props.disabled && props.disabled}
            >
                {props.children ? props.children : props.label}
            </ButtonMui>
        </>
    )
}

export default Button;