import { useEffect } from "react";
import { Hidden, Box, Typography, Divider } from "@mui/material";
import Spacer from "../components/spacer";
import logoMathux from '../assets/img/mathux.png';

const FooterLayout = props => {

    const hangleGetHeight = () => {
        const elem = document.getElementById("footer");
        window.onload = () => {
            if (elem) {
                if (props.handleHeight) {
                    props.handleHeight(elem.clientHeight);
                }
            }
        }
    }

    return(
        <>
            <div id="footer">
                <Hidden smUp>
                    <Divider />
                    <Spacer direction="vertical" size={30} />
                    <a href="mailto:operacional@vitrinerevit.net" style={{ textDecoration: 'none' }}>
                        <Typography 
                            style={{ 
                                color: '#7F8389',
                                textAlign: 'center'
                            }}
                        >
                            operacional@vitrinerevit.net
                        </Typography>
                    </a>
                    <Spacer direction="vertical" size={20} />
                    <Typography 
                        style={{ 
                            color: '#7F8389',
                            textAlign: 'center',
                            width: '90%',
                            display: 'flex',
                            margin: 'auto',
                        }}
                    >
                        © {new Date().getFullYear()} Vitrine Revit. Todos os direitos reservados
                    </Typography>
                    <Spacer direction="vertical" size={20} />
                    <Box 
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            margin: 'auto',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography 
                            style={{ 
                                color: '#7F8389' 
                            }}
                        >
                            Desenvolvido por
                        </Typography>
                        <img 
                            src={logoMathux} 
                            style={{ 
                                width: '80px',
                                marginLeft: '3px'
                            }} 
                        />
                    </Box>
                    <Spacer direction="vertical" size={30} />
                </Hidden>
                <Hidden smDown>
                    <Box>
                        <Divider />
                        <Box 
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                display: 'flex',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                height: '80px'
                            }}
                        >
                            <Typography 
                                sx={{
                                    color: '#7F8389'
                                }}
                            >
                                © {new Date().getFullYear()} Vitrine Revit. Todos os direitos reservados
                            </Typography>
                            <a href="mailto:operacional@vitrinerevit.net" style={{ textDecoration: 'none' }}>
                                <Typography 
                                    style={{ 
                                        color: '#7F8389' 
                                    }}
                                >
                                    operacional@vitrinerevit.net
                                </Typography>
                            </a>
                            <Box 
                                style={{ 
                                    display: 'flex', 
                                    alignItems: 'center' 
                                }}
                            >
                                <Typography 
                                    style={{ 
                                        color: '#7F8389' 
                                    }}
                                >
                                    Desenvolvido por 
                                </Typography>
                                <a target="_blank" href="https://www.mathux.design/">
                                    <img 
                                        draggable={false}
                                        src={logoMathux} 
                                        style={{ 
                                            width: '80px',
                                            marginLeft: '3px'
                                        }} 
                                        onLoad={() => {
                                            hangleGetHeight();
                                        }}
                                    />
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Hidden>
            </div>
        </>
    )
}

export default FooterLayout;