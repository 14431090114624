import axios from 'axios';

let url = "https://api.vitrinedata.com/"; // Production URL API

if (window.location.hostname === 'localhost') {
  url = 'http://localhost:8000';
}

export default axios.create({
  baseURL: url
});