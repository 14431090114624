import { Grid, Box, Typography, IconButton, CircularProgress, Modal } from "@mui/material";
import Spacer from "../../components/spacer";
import { useState } from "react";
import api from "../../utils/api";
import Input from "../../components/input";
import Button from "../../components/button";
import EditIcon from '../../assets/icons/pencil.svg';
import TrashIcon from '../../assets/icons/trash.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '32px',
    p: 4,
    '@media (max-width: 767px)': {
        width: '70vw'
    }
};

const Company = props => {

    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [newCompanyName, setNewCompanyName] = useState(props.company?.name);
    const [newCompanyUrl, setNewCompanyUrl] = useState(props.company?.url);
    const [newCompanyLink, setNewCompanyLink] = useState(props.company?.link);

    const [isLoadingEditCompany, setIsLoadingEditompany] = useState(false);
    const [isLoadingDeleteCompany, setIsLoadingDeleteCompany] = useState(false);

    const handleDeleteCompany = () => {

        setIsLoadingDeleteCompany(true);

        api.post(`/company/delete/${props.company?.id}`, {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingDeleteCompany(false);
            setOpenDelete(!openDelete);
            props.handleGetCompanies();
        })
        .catch(error => {
            setIsLoadingDeleteCompany(false);
            alert("Erro ao deletar empresa");
        });

    }

    const handleEditCompany = () => {

        setIsLoadingEditompany(true);

        api.post(`/company/edit/${props.company?.id}`, {
            name: newCompanyName,
            url: newCompanyUrl,
            link: newCompanyLink
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("tokenStorage")
            }
        })
        .then(res => {
            setIsLoadingEditompany(false);
            setOpenEdit(!openEdit);
            props.handleGetCompanies();
        })
        .catch(error => {
            setIsLoadingEditompany(false);
            alert("Erro ao editar empresa");
        });

    }
    
    return(
        <>
            <Spacer direction="vertical" size={10} />
            <Grid spacing={3} container alignItems="center">
                <Grid item md={3} xs={12}>
                    <Typography variant="body2">{props.company.name}</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>{props.company.url}</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>{props.company.link}</Typography>
                </Grid>
                <Grid item md={3} xs={12} 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end',
                        '@media (max-width: 900px)': {
                            justifyContent: 'flex-start'
                        }
                    }}
                >
                    <img style={{ cursor: 'pointer', marginRight: '20px' }} src={EditIcon} onClick={() => {setOpenEdit(true)}} />
                    <img style={{ cursor: 'pointer' }} src={TrashIcon} onClick={() => {setOpenDelete(true)}} />
                </Grid>
            </Grid>
            <Spacer direction="vertical" size={10} />
            <Modal
                open={openEdit}
                onClose={() => {setOpenEdit(!openEdit)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" fontWeight="bold">
                        Editar empresa
                    </Typography>
                    <Spacer direction="vertical" size={20} />
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Typography>Nome</Typography>
                            <Input placeholder="Digite o nome" fullWidth value={newCompanyName} onChange={(e) => {setNewCompanyName(e.target.value)}} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography>URL</Typography>
                            <Input placeholder="Digite a url" fullWidth value={newCompanyUrl} onChange={(e) => {setNewCompanyUrl(e.target.value)}} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography>Link embed</Typography>
                            <Input placeholder="Digite o link" fullWidth value={newCompanyLink} onChange={(e) => {setNewCompanyLink(e.target.value)}} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box 
                                sx={{ 
                                    display: 'flex',
                                    gap: '10px',
                                    '@media (max-width: 900px)': {
                                        display: 'grid'
                                    }
                                }}
                            >
                                <Button color="gray" onClick={() => {setOpenEdit(!openEdit)}} size="large" variant="contained" fullWidth label="Cancelar" />
                                <Button disabled={!newCompanyName || !newCompanyUrl || !newCompanyLink || isLoadingEditCompany} onClick={() => {handleEditCompany()}} size="large" variant="contained" fullWidth label={isLoadingEditCompany ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Salvar'} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openDelete}
                onClose={() => {setOpenDelete(!openDelete)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" fontWeight="bold">
                        Deletar empresa
                    </Typography>
                    <Spacer direction="vertical" size={20} />
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Typography>{newCompanyName}</Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box 
                                sx={{ 
                                    display: 'flex',
                                    gap: '10px',
                                    '@media (max-width: 900px)': {
                                        display: 'grid'
                                    }
                                }}
                            >
                                <Button color="gray" onClick={() => {setOpenDelete(!openDelete)}} size="large" variant="contained" fullWidth label="Cancelar" />
                                <Button disabled={isLoadingDeleteCompany} onClick={() => {handleDeleteCompany()}} size="large" variant="outlined" fullWidth label={isLoadingDeleteCompany? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Deletar'} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default Company;