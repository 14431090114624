import { Drawer, Box, Typography, Avatar, Tooltip, AppBar, Toolbar } from "@mui/material";
import './default.css';
import { Link } from 'react-router-dom';
import Spacer from "../components/spacer";
import FooterLayout from "./footer";
import { logout } from "../utils/logout";
import { useEffect, useState } from "react";
import LogoutIcon from '../assets/icons/log-out-01.svg';
import HomeIcon from '../assets/icons/home.svg';
import HomeWhiteIcon from '../assets/icons/home-white.svg';
import PieIcon from '../assets/icons/pie.svg';
import PieWhiteIcon from '../assets/icons/pie-white.svg';
import EditIcon from '../assets/icons/edit.svg';
import EditWhiteIcon from '../assets/icons/edit-white.svg';
import BookIcon from '../assets/icons/book.svg';
import BookWhiteIcon from '../assets/icons/book-white.svg';

const drawerWidth = 100;

const menu = [
    {
        'title': 'Visão geral',
        'href': '/visao-geral',
        'icon': HomeIcon,
        'selectedIdon': HomeWhiteIcon
    },
    {
        'title': 'Meu painel',
        'href': '/meu-painel',
        'icon': PieIcon,
        'selectedIdon': PieWhiteIcon
    },
    {
        'title': 'Tutoriais',
        'href': '/tutoriais',
        'icon': BookIcon,
        'selectedIdon': BookWhiteIcon
    },
    {
        'title': 'Central de operações',
        'href': '/central-operacoes',
        'icon': EditIcon,
        'selectedIdon': EditWhiteIcon
    }
]

const DefaultLayout = props => {

    const handleLogout = () => {
        logout()
    }

    const [updatedMenu, setUpdatedMenu] = useState([]);

    useEffect(() => {
        let updatedMenu;
        if (props?.userInfo?.level && props?.userInfo?.level !== 'managerial') {
            updatedMenu = menu.slice(0, -1);
        } else {
            updatedMenu = menu.filter((item, index) => index !== 1 && index !== 2);
        }
        setUpdatedMenu(updatedMenu);
    }, [props?.userInfo?.level]);

    return(
        <>
            <Box 
                sx={{ 
                    paddingBottom: '20px',
                    '@media (min-width: 768px)': {
                        display: 'flex', 
                        position: 'relative' 
                    }
                }}
            >
                <Box
                    component="nav"
                    sx={{ 
                        width: { 
                            sm: drawerWidth 
                        }, 
                        flexShrink: { 
                            sm: 0 
                        } 
                    }}
                >
                    <AppBar 
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            backgroundColor: '#4D0C82',
                            borderRadius: '0px 0px 20px 20px',
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        }}
                        position="static"
                    >
                        <Toolbar>
                            <Link to={props?.userInfo?.level != 'managerial' && "/minha-conta"} className="link">
                                <Avatar style={{ cursor: 'pointer' }} />
                            </Link>
                            <Typography sx={{ flexGrow: 1 }} />
                            {updatedMenu.map((item, index) => (
                                <Link key={index} to={item.href} className="link">
                                    <Tooltip title={item.title}>
                                        <Box 
                                            className={
                                                window.location.pathname == item.href ? 
                                                    'backgroundIconActive' 
                                                : 
                                                    'backgroundIcon'
                                            }
                                        >
                                            <img 
                                                src={window.location.pathname == item.href ? item.selectedIdon : item.icon} 
                                                style={{ 
                                                    display: 'block', 
                                                    margin: 'auto' 
                                                }} 
                                            />
                                        </Box>
                                    </Tooltip>
                                </Link>
                            ))}
                            <img
                                src={LogoutIcon} 
                                onClick={() => {handleLogout()}}
                                style={{ 
                                    marginLeft: window.location.pathname == '/central-operacoes' || window.location.pathname == '/tutoriais' && '5px',
                                    cursor: 'pointer'
                                }} 
                            />
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        anchor="left"
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { 
                                boxSizing: 'border-box', 
                                width: drawerWidth, 
                                border: 'none' 
                            },
                        }}
                    >
                        <Box 
                            sx={{ 
                                backgroundColor: '#4D0C82', 
                                width: '100%', 
                                height: '100%',
                                borderRadius: '0px 30px 30px 0px',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '40px',
                                paddingBottom: '40px'
                            }}
                        >
                            <Box style={{
                                position: 'relative'
                            }}>
                                {props?.userInfo?.level != 'managerial' ?
                                    <Link to="/minha-conta" className="link">
                                        <Tooltip title="Minha conta">
                                            <Avatar style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                    </Link>
                                :
                                    <Avatar />
                                }
                                <Spacer direction="vertical" size={10} />
                                {updatedMenu.map((item, index) => (
                                    <Link key={index} to={item.href} className="link">
                                        <Tooltip title={item.title}>
                                            <Box 
                                                className={
                                                    window.location.pathname == item.href ? 
                                                        'backgroundIconActive' 
                                                    : 
                                                        'backgroundIcon'
                                                }
                                            >
                                                <img 
                                                    src={window.location.pathname == item.href ? item.selectedIdon : item.icon} 
                                                    style={{ 
                                                        display: 'block', 
                                                        margin: 'auto'
                                                    }} 
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Link>
                                ))}
                                <img
                                    src={LogoutIcon} 
                                    onClick={() => {handleLogout()}}
                                    style={{ 
                                        position: 'absolute', 
                                        bottom: 0,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        color: '#FFF',
                                        cursor: 'pointer'
                                    }} 
                                />
                            </Box>
                        </Box>
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ 
                        flexGrow: 1, 
                        p: 3, 
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        paddingBottom: '80px'
                    }}
                >
                    {props.children}
                </Box>
                <Box 
                    sx={{ 
                        '@media (min-width: 901px)': {
                            backgroundColor: props.backgroundColor ? props.backgroundColor : '#FFF', 
                            position: 'fixed', 
                            bottom: 0, 
                            right: 0, 
                            width: `calc(100% - ${drawerWidth}px)`,
                        }
                    }}
                >
                    <FooterLayout />
                </Box>
            </Box>
        </>
    )
}

export default DefaultLayout;