import { Grid, Typography, Box, FormGroup, FormControlLabel, Checkbox, CircularProgress } from "@mui/material"
import Button from "../../components/button"
import Input from "../../components/input"
import Spacer from "../../components/spacer"
import AuthLayout from "../../layouts/auth"
import './newPassword.css'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import API from "../../utils/api"

const NewPassword = props => {

    const location = useLocation();

    const [token, setToken] = useState();

    useEffect(() => {

        let searchParams = new URLSearchParams(location.search);
        let token = searchParams.get('token');
        setToken(token);
    }, [location.search]);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [isLoading, setIsLoading] = useState();

    const handleRecovery = () => {

        if(password != confirmPassword){
            alert("As senhas não coincidem!");
        }else{

            setIsLoading(true);

            API.post('/new-password' , {
                email: email,
                token: token,
                password: password
            })
            .then(res => {

                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setIsLoading(false);

                alert("Senha redefinada. Faço o login!");

            })
            .catch(err => {

                setIsLoading(false);
                alert("Token inválido");
                
            })

        }

    }

    return(
        <>
            <AuthLayout>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Typography className="title">Crie uma nova <br /> senha!</Typography>
                        <Spacer direction="vertical" size={10} />
                        <Typography className="subtitle" color="textSecondary">Criei e confirme sua nova senha para recuperar o acesso!</Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography>E-mail</Typography>
                        <Input value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder="Digite seu e-mail" fullWidth />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography>Senha</Typography>
                        <Input value={password} onChange={(e) => {setPassword(e.target.value)}} placeholder="******" fullWidth type="password" />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography>Confirme a senha</Typography>
                        <Input value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)}} placeholder="******" fullWidth type="password" />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button disabled={!email || !password || !confirmPassword || isLoading} onClick={() => {handleRecovery()}} size="large" variant="contained" fullWidth label={isLoading ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Confirmar'}  />
                    </Grid>
                    <Grid item md={12} xs={12} className="footerForgot">
                        <Link to="login" className="link">
                            <Typography className="forgot">Já tem uma conta? <span>Entrar</span></Typography>
                        </Link>
                    </Grid>
                </Grid>
            </AuthLayout>
        </>
    )
}

export default NewPassword;