import React from 'react'

const Spacer = props => {

    return (
        <>

            {props.direction == "vertical" && (
                <>
                    <div style={{ height: `${props.size}px` }} />
                </>
            )}
            {props.direction == "horizontal" && (
                <>
                    <div style={{ width: `${props.size}px` }} />
                </>
            )}
            
        </>
    );
}

export default Spacer;