import { TextField, IconButton, InputAdornment } from "@mui/material";
import ArrowIcon from '../assets/icons/arrow.svg';

const Input = props => {

    function handleChange(event) {
        if(props.onChange){
            props.onChange(event);
        }
    }

    function handleBlur(event) {
        if(props.onBlur){
            props.onBlur(event);
        }
    }

    function handleKeyDown(event) {
        if(props.onKeyDown){
            props.onKeyDown(event);
        }
    }
    
    return(
        <>
            {(props?.type && props?.type === 'select') && (
                <TextField
                    fullWidth={props?.fullWidth}
                    select
                    label={props?.label}
                    value={props?.value} 
                    placeholder={props?.placeholder}
                    SelectProps={{
                        native: true,
                        IconComponent: () => (
                            <img
                                src={ArrowIcon}
                                alt="Arrow"
                                style={{ width: '16px', height: '16px', marginRight: '8px', marginRight: '26px' }}
                            />
                        )
                    }}
                    sx={{
                        select: { 
                            backgroundColor: '#FFF',
                            borderRadius: '30px',
                            '&:focus': {
                                borderRadius: '30px',
                            }
                        },
                        width: props?.width && props?.width
                    }}
                    InputProps={{
                        sx: { borderRadius: '30px', backgroundColor: '#FFF' }
                    }}
                    size={props?.size && props?.size}
                    onChange={handleChange}
                >
                    {props.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
            )}
            {(!props?.type || props?.type === 'input' || props?.type === 'password') && (
                <TextField 
                    fullWidth={props?.fullWidth}
                    value={props?.value} 
                    placeholder={props?.placeholder}
                    type={props?.type && props?.type}
                    sx={{
                        '& input': { 
                            backgroundColor: '#FFF',
                            borderRadius: '30px',
                            border: 'none'
                        },
                        width: props?.width && props?.width
                    }}
                    InputProps={{
                        sx: { borderRadius: '30px', border: 'none', backgroundColor: '#FFF' },
                        endAdornment: (
                            props?.endAdornment && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            props?.handleClickEndAdornment();
                                        }}
                                        sx={{ color: '#EA1A7F' }}
                                    >
                                        {props?.endAdornmentIcon}
                                    </IconButton>
                                </InputAdornment>
                            )
                        )
                    }}
                    size={props?.size && props?.size}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
            )}
        </>
    )
}

export default Input;